import React, { Fragment } from 'react';
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import moment from 'moment-timezone';
import Helmet from 'react-helmet'
import { rhythm } from "../utils/typography"
import { useQueryParam, StringParam } from "use-query-params"
import gamePlayBtn from "../img/btn-play.png";
import gameEndBtn from "../img/cookinburger_end_JP2.png";
import Layout from "../components/layout"
const $ = require("jquery")

const propTypes = {
  data: PropTypes.object.isRequired,
}

function itemSort() {
  let ctg = $('input[name="ctg"]:checked').val();
  let rank = $('input[name="rank"]:checked').val();
  let baseSelector = 'ul.l-nft-section-list li';
  let sortSelector = '';

  if( ctg == 'cart' ) {
    $('#rank-new').removeClass('disnon');
  } else {
    $('#rank-new').addClass('disnon');
  }

  if( ctg ) sortSelector = sortSelector+'.'+ctg;
  if( rank ) sortSelector = sortSelector+'.'+rank;
  let selected = $(baseSelector + sortSelector);
  $(baseSelector).not(sortSelector).addClass('disnon');
  selected.removeClass('disnon');
  $('#current').text(selected.length);
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

const News = ({ node }) => {
  const dateDisplay = node.displayDate.split('T')[0].replace(/-/g,'.');
  return (
  <div>
    <Link
      style={{ color: `#4E2714`, textDecoration: `none`, fontSize: "1.5rem", fontWeight: 600 }}
      to={`/${getLangDir(node.node_locale)}/news/${node.slug}/`}
    >
      <div
        style={{
          display: `flex`,
          alignItems: `center`,
          fontSize: 14,
          paddingBottom: rhythm(1),
          marginBottom: rhythm(1),
        }}
      >
        <div className="news-list-day">{dateDisplay}</div>
        <div className="news-list-title">{node.title}</div>
      </div>
    </Link>
  </div>
  )
}

const GameLink = () => {
  let gameLink = "https://game.cookinburger.com";
  const [rid, setRid] = useQueryParam('rid', StringParam);

  if (typeof window !== 'undefined') {
    if (rid === undefined && localStorage.getItem("rid") !== null) {
      gameLink = gameLink+"?rid="+localStorage.getItem('rid');
    } else {
      gameLink = rid !== undefined ? gameLink+"?rid="+rid : gameLink;
    }
  }
  return (
    <div className="btn-play"><img src={gameEndBtn} alt="Click to start!" /></div>
  )
}

function handleClick() {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem("rid") !== null) {
      var labelValue = '';
      var rId = '';
      var deferCampaignLog = new $.Deferred().resolve();
      deferCampaignLog.promise()
        .then(function() {
            rId = localStorage.getItem("rid");
            return rId
        })
        .then(function(rId) {
            if (rId === null) {
                rId = 'organic'
            }
            labelValue = rId+' | '+localStorage.getItem("cookinburger_guid_raw")+'-'+rId+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
            dataLayer.push({
                'event' : 'gaEvent',
                'eventCategory': 'Conversion: Campaign',
                'eventAction': 'click',
                'eventLabel': labelValue
            });
            var dataCampaignObj = {
                "Property": 'cookinburger',
                "Guid": localStorage.getItem("cookinburger_guid_raw")+'-'+rId,
                "Rid": rId,
                "Locale": window.pmApp.checkLocale(window.location.href),
                "Lp": window.location.href,
                "Reg": "0",
                "FT" : "0",
                "Conv": "1",
                "Username" : 'n/a',
                "Email": 'n/a',
                "Country": 'n/a',
                "City": 'n/a',
                "LonLat": 'n/a',
                "Ip": 'n/a',
                "Timezone": 'n/a',
                "Ref": localStorage.getItem("ref") !== null ? localStorage.getItem("ref") : 'n/a',
                "Lang": language
            };
            if (localStorage.getItem("game_conv_rid") === null) {
                window.pmApp.sendCampaignLog(dataCampaignObj);
            } else {
                var convDoneArray = localStorage.getItem("game_conv_rid");
                convDoneArray = convDoneArray.split(",");
                if (convDoneArray.indexOf(rId) === -1) {
                    window.pmApp.sendCampaignLog(dataCampaignObj);
                }
            }
        })
        .done(function() {
            return true;
        })
    } else {
        labelValue = 'organic | '+localStorage.getItem("cookinburger_guid_raw")+'-organic'+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
        dataLayer.push({
            'event' : 'gaEvent',
            'eventCategory': 'Conversion: Organic',
            'eventAction': 'click',
            'eventLabel': labelValue
        });
    }
  }
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        error: false,
        fetchedData: [],
        progress: false,
        dataAvail: false,
    };
  }

  componentDidMount() {
    const total = $('ul.l-nft-section-list li');
    $('#total').text(total.length);
    itemSort();

    $('input[name="ctg"]').on('change', function() {
      itemSort();
    });

    $('input[name="rank"]').on('change', function() {
      itemSort();
    });
  }

  shuffleArticle = (data, topNews) => {
    let articles = [], fixTopIndex = null;
    const numLength = topNews && topNews.length > 0 ? data.length - topNews.length : data.length;
    for (let i=0; i<numLength; i++) {
      if (data[i]['node']['positionTopFixed'] === null || data[i]['node']['positionTopFixed'] === false) {
        articles.push(data[i]);
      } else if (data[i]['node']['positionTopFixed'] == true) {
        fixTopIndex = i;
      }
    }
    if (fixTopIndex !== null) {
      articles.unshift(data[fixTopIndex]);
    }
    return articles;
  }

  render() {
    const jaTopNews = this.props.data.jaTop.edges;
    var jaNewsEdges = [];
    if (this.props.data.ja !== null) {
      jaNewsEdges = this.shuffleArticle(this.props.data.ja.edges, jaTopNews);
    }
    // const users = this.props.data.user !== undefined ? this.props.data.user.edges : false;
    const { loading, fetchedData, progress, dataAvail } = this.state;
    return (
      <Layout data={this.props.data} location={this.props.location} key={loading}>
        <Helmet>
          <title>誰でも簡単に遊べるマルチタスクゲーム　「Cookin&#x27; Burger」！</title>
          <meta name="description" content="誰でも簡単に遊べるマルチタスクゲーム　「Cookin&#x27; Burger」！" />
          <meta property="og:image" content="https://cookinburger.com/assets/img/ogp.png" />
          <meta property="og:title" content="Cookin' Burgerで遊んでいるよ！" />
          <meta property="og:description" content="誰でも簡単に遊べるマルチタスクゲーム「Cookin&#x27; Burger」!" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Cookin' Burgerで遊んでいるよ！" />
          <meta name="twitter:description" content="誰でも簡単に遊べるマルチタスクゲーム「Cookin&#x27; Burger」!" />
          <meta name="twitter:image" content="https://cookinburger.com/assets/img/ogp.png" />
        </Helmet>
        <div className="l-main">

          <div className="l-key-visual">
            <div className="section-inner_m">
              <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-key-visual-logo wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img-ja/logo-cb.png" alt="2022年春サービス開始決定！" />
              </div>
              <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="700" data-sal-easing="ease"
               className="l-key-visual-title wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.7s" data-wow-offset="0">
                <img className="l-key-visual-title-logo" src="../../assets/img-ja/playmining-logo.png" alt="playmining" />
                <img src="../../assets/img-ja/key-visual-title.png" alt="New PlayMining Platform Game Cookin' Burger Set to launch in Spring 2022!" />
                {/* <a href="https://game.cookinburger.com/" target="_blank"><div className="btn-play">
                  <img src="../../assets/img-ja/btn-play.png" alt="今すぐプレイする" /></div></a> */}
                <GameLink />
              </div>
              <div data-sal="slide-up" data-sal-delay="400" data-sal-duration="700" data-sal-easing="ease"
               className="l-key-visual-item wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img-ja/key-visual-item.png" alt="" />
              </div>
            </div>
          </div>

          <div className="section-inner_m" style={{marginTop: "-24px"}}>
            <div className="l-news-section">
                <p className="l-about-section-cap" style={{ letterSpacing: "-0.02em" }}>
                  諸般の事情により2025年1⽉31⽇(⾦)12:00(JST)をもって『Cookin' Burger』は<br />サービスを終了させていただくこととなりました。サービス終了までのスケジュールは下記の通りです。
                </p>

                <p>
                  <b>12⽉30⽇(⽉) 15:00(JST)</b> 最終シーズン開始<br /><br />
                  <b>1⽉27⽇(⽉) 12:00(JST)</b> 最終シーズン終了、DEAPcoin(DEP)の排出停⽌、スカラーシップ機能および現契約の強制解除、ゲーム内ショップの閉鎖<br /><br />
                  <b>1⽉31⽇(⾦) 12:00(JST)</b> サービス終了、アクセス停⽌<br /><br />
                  <b>2⽉3⽇(⽉)</b> 最終シーズンの給料配布
                </p>

                <p style={{marginTop: "24px"}}>
                  <b>なお、ショップNFTにつきましては、サービス終了後も引き続きPlayMining NFT上で⼆次取引できます。<br />
                  最後に、⻑い間ご愛顧いただきまして誠にありとうございました。</b>
                </p>
            </div>
          </div>

          <div className="section-inner_m">
            <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
             className="l-news-section wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <ul className="news-list">
                  {jaTopNews.map(({ node }, i) => (
                    <News node={node} key={node.id} />
                  ))}
                  {jaNewsEdges.map(({ node }, i) => (
                    <News node={node} key={node.id} />
                  ))}
                </ul>
                <Link to={`/ja/news/`}><div className="news-list-btn">More</div></Link>
            </div>
          </div>

          <div className="l-about-section">
            <div className="section-inner_m">

              <div className="l-about-section-box">
                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img-ja/title-02.png" alt="Cookin’ Burger』とは？" />
                </div>

                <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-about-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  『Cookin’ Burger』は、バーガーショップの店員として、<br />様々なタイプのお客のオーダーを受け、正確かつ素早く料理を提供して<br />お店の評判をあげることを目指すマルチタスク・アクションゲームです。<br />
                </p>

                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-ui wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img-ja/ui.png" alt="" />
                </div>
                <p className="l-about-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  世界中の人々に馴染みの深いハンバーガーショップの経営を疑似体験し、<br />
                  シンプルなゲーム性ながら、お客さんの好みを覚えたり、より効率的な調理の順序を<br />
                  考えたりと、実際のお店を切り盛りするかのようなテクニックが要求されます。
                </p>

                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-img wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img-ja/about-section-img.png" alt="" />
                </div>

                <ul className="l-about-section-point">
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img-ja/icon-01.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                          ショップNFTを買うことでお店のオーナーになることが出来ます。お店には様々なグレードがあり、屋台など安価で料理手順のシンプルなものから、大型店舗などの高価で難易度も高いが大きなリターンが見込めるものまで、自分のレベルにあったお店にアップグレードしていくことが出来ます。
                        </p>
                    </li>
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img-ja/icon-02.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                          ショップNFTには『評判』というパラメータがあり、お客さんのオーダーや好みを覚えて素早く提供することで常連客が増えるとリピート率がアップして売上や評判が上がります。注文を間違えたり、焼き加減がいまいち、制限時間内に提供できない、などを繰り返すと評判が下がります。また、ゲームをプレイするたび『耐久値』というパラメーターが目減りし、ゼロになるとプレイできなくなります。
                        </p>
                    </li>
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img-ja/icon-03.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                          NFTを持っているオーナーは、アルバイト（スカラー）を雇ってゲームを自分の代わりにプレイしてもらうことが出来ます。アルバイト（スカラー）はゲームの腕を磨いてより多くのお客さんのオーダーをさばき、お店を繁盛させることによって他のお店からより良い条件でスカウトしてもらえるようになります。
                        </p>
                    </li>
                </ul>
              </div>

              <ul data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-footer-sns-list wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <li><a href="https://discord.gg/xWeHGdt" target="_blank"><img src="../../assets/img/sns-ico-dc.png" alt="" /></a></li>
                  <li><a href="https://twitter.com/PlayMining_SG" target="_blank"><img src="../../assets/img/sns-ico-x.png" alt="" /></a></li>
                  <li><a href="https://www.facebook.com/PlayMining/" target="_blank"><img src="../../assets/img/sns-ico-fb.png" alt="" /></a></li>
                  <li><a href="https://t.me/DEAPcoin_group" target="_blank"><img src="../../assets/img/sns-ico-tg.png" alt="" /></a></li>
                  <li><a href="https://www.youtube.com/channel/UCGWmK0RLV4SB_PSXpj2j6dw" target="_blank"><img src="../../assets/img/sns-ico-yt.png" alt="" /></a></li>
              </ul>
            </div>
          </div>

        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = propTypes

export default IndexPage

export const pageQuery = graphql`
  query PageDeQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    ja: allContentfulPost(filter: { node_locale: { eq: "ja" } }, limit: 3, sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          slug
          displayDate
          positionTopFixed
          createdAt
        }
      }
    }
    jaTop: allContentfulPost(filter: { node_locale: { eq: "ja" }, positionTopFixed: { eq: true} }, limit: 3, sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          slug
          displayDate
          positionTopFixed
          createdAt
        }
      }
    }
    # user: allRandomUser {
    #   edges {
    #     node {
    #       kind
    #       start
    #       end
    #       name {
    #         user_name
    #         value
    #         position
    #         dep
    #       }
    #     }
    #   }
    # }
  }
`
